import { Box, Tooltip, Typography } from '@partstech/ui';
import { useMemo } from 'react';
import snowedMountains from 'images/icons/snowed-mountains.svg';
import { Chip } from '../Chip';
import type { ProductAttribute } from 'types/product';

type Props = {
  attribute: ProductAttribute;
};

const MOUNTAIN_SNOWFLAKE = '3 Peak Mountain Snowflake';

export const Certification = ({ attribute }: Props) => {
  const containsSnowflake = attribute.value.includes(MOUNTAIN_SNOWFLAKE);

  const filteredValues = useMemo(
    () => attribute.value.split(',').filter((value) => value !== MOUNTAIN_SNOWFLAKE),
    [attribute.value]
  );

  if (!attribute.value) {
    return null;
  }

  return (
    <Tooltip tooltip={attribute.value} disabled={!containsSnowflake} allowAutoPositioning>
      <Box display="flex" gap={1}>
        {containsSnowflake && (
          <Chip background="primaryLight10">
            <img src={snowedMountains} alt={MOUNTAIN_SNOWFLAKE} />
          </Chip>
        )}

        {attribute.value !== MOUNTAIN_SNOWFLAKE &&
          filteredValues.map((value) => (
            <Chip key={value} background="primaryLight10">
              <Typography variant="overline" color="white" textTransform="uppercase">
                {value}
              </Typography>
            </Chip>
          ))}
      </Box>
    </Tooltip>
  );
};
