import { createSelector } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { CATALOG_GROUP } from 'constant';
import { createBaseProductFromQuery } from 'factories';
import { selectRootState } from 'store/utils';
import { getPreferredBrandsByProducts, isPreferredBrand } from 'utils';
import { useLazyGetCatalogProductsQuery, api as generatedApi } from './GetCatalogProducts.generated';
import { getSearchInputWithoutAttributes } from './getSearchInputWithoutAttributes';
import type { BaseProductFragment } from './BaseProductFragment.generated';
import type { GetCatalogProductsQueryVariables } from './GetCatalogProducts.generated';
import type { QueryStatus } from '@reduxjs/toolkit/query';
import type { Vehicle } from 'entities/vehicle';
import type { Product, SupplierAccount } from 'models';
import type { CatalogSearchInput } from 'shared/api';
import type { PreferredBrandUnified } from 'types/preferredBrand';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['Product'],
  endpoints: {
    GetCatalogProducts: {
      providesTags: () => [{ type: 'Product' }],
      serializeQueryArgs: ({ queryArgs }) => {
        if (!queryArgs.searchInput) {
          return '';
        }

        return `Product(${JSON.stringify(getSearchInputWithoutAttributes(queryArgs.searchInput))})`;
      },
    },
  },
});

const emptyProducts: BaseProductFragment[] = [];

type Props = {
  onSuccess?: (products: BaseProductFragment[]) => void;
};

export const useGetLazyCatalogProducts = ({ onSuccess }: Props = {}) => {
  const [trigger] = useLazyGetCatalogProductsQuery();

  const getCatalogProducts = useCallback(
    async (params: GetCatalogProductsQueryVariables) => {
      const { isSuccess, data } = await trigger(params);

      if (isSuccess) {
        onSuccess?.(data.catalogProducts ?? emptyProducts);
      }
    },
    [onSuccess, trigger]
  );

  return {
    getCatalogProducts,
  };
};

type CatalogSearchResults = { searchInput: CatalogSearchInput } | null;

type CreateProductParams = {
  accounts: SupplierAccount[];
  preferredBrands: PreferredBrandUnified[];
  maxPreferredBrandsCount: number;
  showRetailPrice: boolean;
  vehicle: Vehicle | null;
};

export const selectCatalogSearchResults = createSelector(
  [selectRootState, (_, params: CatalogSearchResults) => params, (_, __, data: CreateProductParams) => data],
  (rootState, params, data): Record<string, { status: QueryStatus; products: Product[]; errors?: string[] }> => {
    if (!params) {
      return {};
    }

    const { searchInput } = params;

    const result = api.endpoints.GetCatalogProducts.select({ searchInput })(rootState);

    const products = result.data?.catalogProducts ?? emptyProducts;
    const productsPreferredBrands = getPreferredBrandsByProducts(
      data.preferredBrands,
      products,
      data.maxPreferredBrandsCount
    );

    return {
      [CATALOG_GROUP]: {
        status: result.status,
        products: products.map((product) =>
          createBaseProductFromQuery({
            product,
            vehicleId: data.vehicle?.id,
            isPreferred: isPreferredBrand(productsPreferredBrands)(
              product.brand?.id ?? '',
              product.brand?.name ?? '',
              product.partType?.id ?? ''
            ),
            showRetailPrice: data.showRetailPrice,
          })
        ),
        errors: result.error?.message ? [result.error.message] : undefined,
      },
    };
  }
);
