import { isNotNull } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { useGetAddresses } from 'entities/address';
import { createStoreFromQueryData, createSupplierAccountFromData, createSupplierFromQueryData } from 'factories';
import { getMissingStores } from 'store/entities/store';
import { changePauseRequest, setRequestCount } from 'store/entities/supplierAccounts';
import { filterSupplierAccounts, updateSupplierAccountAction } from 'utils';
import { useGetCurrentUser } from '../useGetCurrentUser';
import { useOnboardSteps } from '../useOnboardSteps';
import { api as generatedApi } from './GetSupplierAccounts.generated';
import type { GetSupplierAccountsQuery } from './GetSupplierAccounts.generated';
import type { SupplierAccountType } from 'features/supplierAccount';
import type { SupplierAccount } from 'models';
import type { RootState } from 'store';

type CurrentUser = NonNullable<GetSupplierAccountsQuery['currentUser']>;
type ActiveMember = NonNullable<CurrentUser['activeMember']>;
type Shop = NonNullable<ActiveMember['shop']>;
type Accounts = NonNullable<Shop['accounts']>;

const emptyAccounts: Accounts = [];

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['SupplierAccount'],
  endpoints: {
    GetSupplierAccounts: {
      providesTags: (response: GetSupplierAccountsQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;

        if (!shop) {
          return [];
        }

        const supplierAccounts =
          shop?.accounts?.map((account) => ({
            type: 'SupplierAccount' as const,
            id: account.id,
          })) ?? [];

        return ['SupplierAccount', ...supplierAccounts];
      },
      onQueryStarted: async (input, { queryFulfilled, dispatch, getState }) => {
        try {
          const rootState = getState() as RootState;

          dispatch(setRequestCount(rootState.entities.supplierAccounts.totalRequestsLeft - 1));
          dispatch(changePauseRequest(true));

          const accounts = (await queryFulfilled).data.currentUser?.activeMember?.shop?.accounts ?? [];
          const storeIds = accounts
            .map((account) => account.store?.id)
            .filter(isNotNull)
            .map(Number);

          if (storeIds.length > 0) {
            dispatch(getMissingStores(storeIds));
          }

          if (typeof input === 'object' && Array.isArray(input.ids)) {
            input.ids.forEach((id) => {
              const account = accounts.find((entity) => entity.id === id);

              if (account) {
                dispatch(updateSupplierAccountAction(id, account));
              }
            });
          }
        } catch {
          /* empty */
        } finally {
          dispatch(changePauseRequest(false));
        }
      },
    },
  },
});

const { useGetSupplierAccountsQuery, endpoints } = api;

type Args = {
  ids?: string[];
};

type Params = {
  skip?: boolean;
  pollingInterval?: number;
};

const useGetSupplierAccounts = ({ ids }: Args = {}, { skip = false, pollingInterval }: Params = {}) => {
  const { shop, hasCurrentUser, activeMember } = useGetCurrentUser();
  const { isOnboardCompleted } = useOnboardSteps();
  const { defaultAddress } = useGetAddresses({ skip: !activeMember });

  const { data, ...props } = useGetSupplierAccountsQuery(
    { ids },
    {
      skip: !hasCurrentUser || !isOnboardCompleted || skip,
      pollingInterval,
    }
  );

  const supplierAccounts = data?.currentUser?.activeMember?.shop?.accounts ?? emptyAccounts;

  const accounts = useMemo(
    () =>
      supplierAccounts
        .map((account) => {
          const supplier = account.supplier ? createSupplierFromQueryData(account.supplier) : null;

          const store = account.store
            ? createStoreFromQueryData(account.store, supplier, defaultAddress?.coordinates ?? null, shop?.pickupRadius)
            : null;

          return createSupplierAccountFromData(account, supplier, store);
        })
        .sort((a, b) => {
          if (a.priority > b.priority) {
            return 1;
          }

          if (a.priority < b.priority) {
            return -1;
          }

          return 0;
        }),
    [defaultAddress?.coordinates, shop?.pickupRadius, supplierAccounts]
  );

  const accountsMap = useMemo(
    () =>
      accounts.reduce<Record<SupplierAccount['id'], SupplierAccount>>(
        (map, account) => ({ ...map, [account.id]: account }),
        {}
      ),
    [accounts]
  );

  const filterAccountsByType = useCallback(
    (type: SupplierAccountType) => filterSupplierAccounts(accounts, type),
    [accounts]
  );

  const getAccountById = useCallback((id: string) => accountsMap[id] ?? null, [accountsMap]);

  const getAccountByStoreId = useCallback(
    (id: string) => accounts.find((account) => account.store?.id === id) ?? null,
    [accounts]
  );

  const getAccountsBySupplierId = useCallback(
    (id: string | null) => accounts.filter((account) => account.supplier?.id === id),
    [accounts]
  );

  return {
    accounts,
    accountsMap,
    filterAccountsByType,
    getAccountById,
    getAccountByStoreId,
    getAccountsBySupplierId,
    ...props,
  };
};

export { useGetSupplierAccounts, endpoints };
