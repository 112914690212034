import { createSelector } from '@reduxjs/toolkit';
import { LOCAL_INVENTORY } from 'constant';
import { createBaseLocalInventoryProductFromQuery } from 'factories/product';
import { selectRootState } from 'store/utils';
import { isPreferredBrand, getPreferredBrandsByProducts } from 'utils';
import { useLazyGetLocalInventoryProductsQuery, api as generatedApi } from './GetLocalInventoryProducts.generated';
import type { LocalInventoryProductFragment } from './LocalInventoryProductFragment.generated';
import type { QueryStatus } from '@reduxjs/toolkit/query';
import type { Vehicle } from 'entities/vehicle';
import type { Product, SupplierAccount } from 'models';
import type { LocalInventorySearchInput } from 'shared/api';
import type { PreferredBrandUnified } from 'types/preferredBrand';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['Product'],
  endpoints: {
    GetLocalInventoryProducts: {
      providesTags: () => [{ type: 'Product' }],
    },
  },
});

const emptyProducts: LocalInventoryProductFragment[] = [];

export const useGetLazyLocalInventoryProducts = () => {
  const [trigger] = useLazyGetLocalInventoryProductsQuery();

  return {
    getLocalInventoryProducts: trigger,
  };
};

type LocalInventorySearchResults = { searchInput: LocalInventorySearchInput } | null;

type CreateProductParams = {
  accounts: SupplierAccount[];
  preferredBrands: PreferredBrandUnified[];
  maxPreferredBrandsCount: number;
  showRetailPrice: boolean;
  vehicle: Vehicle | null;
};

export const selectLocalInventorySearchResults = createSelector(
  [selectRootState, (_, params: LocalInventorySearchResults) => params, (_, __, data: CreateProductParams) => data],
  (rootState, params, data): Record<string, { status: QueryStatus; products: Product[]; errors?: string[] }> => {
    if (!params) {
      return {};
    }

    const { searchInput } = params;

    const result = api.endpoints.GetLocalInventoryProducts.select({ searchInput })(rootState);

    const products = result.data?.localInventoryProducts ?? emptyProducts;
    const productsPreferredBrands = getPreferredBrandsByProducts(
      data.preferredBrands,
      products,
      data.maxPreferredBrandsCount
    );

    return {
      [LOCAL_INVENTORY]: {
        status: result.status,
        products: products.map((product) => {
          const supplierAccount = data.accounts.find((account) => account.id === product.supplier?.id);

          return createBaseLocalInventoryProductFromQuery({
            account: supplierAccount,
            product,
            vehicleId: data.vehicle?.id,
            isPreferred: isPreferredBrand(productsPreferredBrands)(
              product.brand?.id ?? '',
              product.brand?.name ?? '',
              product.partType?.id ?? ''
            ),
            showRetailPrice: data.showRetailPrice,
          });
        }),
        errors: result.error?.message ? [result.error.message] : undefined,
      },
    };
  }
);
