import { useSnackbar, useMedia } from '@partstech/ui';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { selectLastAddedId } from 'store/entities/supplierAccounts';
import { useGetSupplierAccounts } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { useOpenAddSupplierModal } from './useOpenAddSupplierModal';
import type { SupplierAccountType } from 'features/supplierAccount';
import type { SupplierAccount } from 'models';
import type { PathParams } from 'utils/url';

const getSupplierAccountFilter = (account: SupplierAccount): SupplierAccountType => {
  if (account.supplier?.isTire) {
    return 'tires';
  }

  if (account.supplier?.isDealer) {
    return 'dealers';
  }

  return 'parts';
};

type Args = {
  isRestrictedEX?: boolean;
};

export const useLastAddedSupplierAccount = ({ isRestrictedEX }: Args) => {
  const { isMobile, isTablet } = useMedia();

  const { tab: activeTab = 'all' } = useParams<PathParams>();
  const { addSnackbar } = useSnackbar();

  const { getAccountById } = useGetSupplierAccounts();

  const supplierAccountId = useAppSelector(selectLastAddedId);

  const account = useMemo(
    () => getAccountById(String(supplierAccountId) ?? '') ?? null,
    [getAccountById, supplierAccountId]
  );

  const { open: openAddSupplierModal } = useOpenAddSupplierModal();

  const snackbarContent = useMemo(() => {
    if (isRestrictedEX && account?.supplier?.isPaid) {
      return {
        header: `${account?.supplier?.name} was successfully requested`,
        label: `Payment required to start the connect process with the supplier.`,
      };
    }

    return {
      header: `${account?.supplier?.name}  was successfully requested`,
      label: `We'll let you know when your connection is approved.`,
    };
  }, [account?.supplier?.isPaid, account?.supplier?.name, isRestrictedEX]);

  const openAddAccountSnackbar = useCallback(() => {
    if (!account?.id) {
      return;
    }

    if (!snackbarContent) {
      return;
    }

    const isMobileDevice = isMobile || isTablet;

    addSnackbar({
      variant: 'success',
      header: snackbarContent.header,
      label: snackbarContent.label,
      position: { horizontal: 'middle', vertical: 'bottom', offsetY: isMobileDevice ? 16 : undefined },
      fullWidth: isMobileDevice,
      isPermanent: isMobileDevice,
      button: isMobileDevice ? 'View' : undefined,
      onClick: () =>
        openAddSupplierModal(activeTab, {
          activeScreen: 'my-suppliers',
          supplierAccountFilter: getSupplierAccountFilter(account),
        }),
    });
  }, [account, snackbarContent, isMobile, isTablet, addSnackbar, openAddSupplierModal, activeTab]);

  return { account, openAddAccountSnackbar };
};
