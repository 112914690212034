import type { SupplierAccountType } from 'features/supplierAccount';
import type { SupplierAccount } from 'models';
import type { AccountStatus } from 'shared/api';

export const filterSupplierAccounts = (
  accounts: SupplierAccount[],
  type: SupplierAccountType,
  status?: AccountStatus
) =>
  accounts.filter((account) => {
    const isMatchingStatus = status ? status === account.status : true;

    if (!isMatchingStatus) {
      return false;
    }

    if (type === 'parts') {
      return !account.supplier?.isTire;
    }

    if (type === 'tires') {
      return account.supplier?.isTire;
    }

    if (type === 'dealers') {
      return account.supplier?.isDealer;
    }

    return true;
  });
