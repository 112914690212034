import { css } from '@emotion/react';
import { Box, Button, Divider, Icon, Loader, Skeleton, TextField, Typography, useMedia } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { CallForAvailability } from 'components/Part/CallForAvailability';
import { PartImage } from 'components/PartImage';
import { StoreAvailability } from 'components/Product/StoreAvailability';
import { currency } from 'shared/lib/string';
import { MobileMenu } from './components/MobileMenu';
import { styles } from './StockOrderRow.styles';
import type { AvailabilityLine, StockOrderLine } from 'models';
import type { FocusEvent } from 'react';

export type StockOrderRowProps = {
  index: number;
  isLoading: boolean;
  line: StockOrderLine;
  onAvailableStoreChange: (availableStore: AvailabilityLine, index: number) => void;
  onMoreResultsClick: ((index: number) => void) | null;
  onQuantityChange: (value: string, index: number) => void;
  onRemoveRow: (index: number) => void;
  hasMoreResults: boolean;
};

export const StockOrderRow = ({
  index,
  isLoading,
  line,
  onAvailableStoreChange,
  onMoreResultsClick,
  onQuantityChange,
  onRemoveRow,
  hasMoreResults,
}: StockOrderRowProps) => {
  const { isMobile } = useMedia();

  const { searchQuery, brandName, quantity, selectedProduct, selectedStore, errorMessage } = line;

  const { open } = useCreateModal(MobileMenu, {
    size: 'bottomSheet',
    searchQuery,
    onMoreResultsClick: () => onMoreResultsClick && onMoreResultsClick(index),
    onRemoveRow: () => onRemoveRow(index),
  });

  const handleQuantityChange = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onQuantityChange(e.target.value, index);
    },
    [index, onQuantityChange]
  );

  const handleAvailableStoreChange = useCallback(
    (store: AvailabilityLine) => {
      onAvailableStoreChange(store, index);
    },
    [index, onAvailableStoreChange]
  );

  const handleRemoveRow = useCallback(() => {
    onRemoveRow(index);
  }, [index, onRemoveRow]);

  const handleMoreResultsClick = useCallback(() => {
    onMoreResultsClick && onMoreResultsClick(index);
  }, [index, onMoreResultsClick]);

  const handleOpenSheet = useCallback(() => open(), [open]);

  const tireSize = useMemo(() => selectedProduct?.getAttributeByName('TiresSize')?.value, [selectedProduct]);

  const shouldShowInput =
    !selectedProduct?.quote ||
    !(selectedProduct.quote.isCallSupplier() || selectedProduct.quote.isCallAvailability()) ||
    !selectedProduct?.store?.phone;

  if (isLoading) {
    return (
      <Box display="flex" minHeight={20} py={3} px={2} gap={2} data-testid="loader">
        <Loader size="medium" />
        <Box display="flex" width="100%" flexDirection="column" gap={3}>
          <Skeleton height="12px" width="65%" />
          <Skeleton height="12px" width="30%" />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Divider />

      <Box
        py={2}
        css={selectedProduct ? styles.container(!shouldShowInput) : styles.emptyRowContainer}
        data-testid="row"
      >
        {!selectedProduct && (
          <Box css={styles.emptyMessage} display="flex" gap={2} alignItems="center">
            <Icon name="error" color="negative" />
            <Typography variant="bodyText2" color="subtleText">
              {'No exact matches for part '}
              <Typography variant="subtitle2" color="subtleText" component="span">
                {searchQuery} {brandName && `- ${brandName}`}
              </Typography>
            </Typography>
            {isMobile && (
              <Typography variant="caption" color="subtleText">
                Tap more icon to view more results
              </Typography>
            )}
          </Box>
        )}
        {selectedProduct && (
          <>
            <PartImage
              css={styles.image}
              partImage={selectedProduct.media?.getFirstImage()?.preview}
              alignSelf="center"
            />
            <Box css={styles.details}>
              <Typography variant="subtitle2">{selectedProduct.title}</Typography>
              <Typography
                variant="overline"
                color="subtleText"
                css={css`
                  display: block;
                `}
              >
                {selectedProduct.brand?.lineCode} {selectedProduct.partNumber?.partNumber}
              </Typography>
              {tireSize && (
                <Typography variant="caption">
                  Size:{' '}
                  <Typography component="span" variant="overline" color="subtleText">
                    {tireSize}
                  </Typography>
                </Typography>
              )}
            </Box>
            <Box css={styles.selector}>
              <StoreAvailability
                allowSelect={selectedProduct.supplier?.canSelectPartStore}
                onStoreChange={handleAvailableStoreChange}
                quote={selectedProduct.quote}
                store={selectedStore || selectedProduct.quote?.lines[0]}
                usePortal
              />
            </Box>
          </>
        )}
        {!isMobile && hasMoreResults && (
          <Button
            css={styles.moreResults}
            leadingIcon="swap_horiz"
            onClick={handleMoreResultsClick}
            size="dense"
            variant="text"
          >
            More results
          </Button>
        )}
        {selectedProduct && (
          <>
            {shouldShowInput && (
              <Box textAlign="center" css={styles.input} width={18} justifySelf="end">
                <TextField
                  disabled={!selectedProduct.quote?.isAddToCart()}
                  error={errorMessage}
                  max={999}
                  min={1}
                  precision={0}
                  name="quantity"
                  onBlur={handleQuantityChange}
                  type="number"
                  value={!selectedProduct.quote?.isAddToCart() ? 0 : quantity}
                />
              </Box>
            )}
            <CallForAvailability
              canBuy={selectedProduct.quote?.isAddToCart()}
              css={styles.actionMessage}
              color="negative"
              name={selectedProduct.supplier?.name}
              phone={selectedProduct.store?.phone ?? ''}
              shouldCallAvailability={selectedProduct.quote?.isCallAvailability()}
              shouldCallSupplier={selectedProduct.quote?.isCallSupplier()}
              variant="caption"
            />
            {(!isMobile || (isMobile && shouldShowInput)) && (
              <Typography variant={isMobile ? 'h3' : 'subtitle1'} noWrap css={styles.price(Boolean(errorMessage))}>
                x {currency(selectedProduct?.price || 0)}
              </Typography>
            )}
          </>
        )}
        {isMobile ? (
          <Button
            css={styles.action}
            data-testid="moreActionButton"
            leadingIcon="more_vert"
            onClick={handleOpenSheet}
            size="dense"
            variant="light"
          />
        ) : (
          <Button
            className="deleteIcon"
            css={styles.action}
            data-testid="deleteRowButton"
            iconColor="negative"
            leadingIcon="delete"
            onClick={handleRemoveRow}
            disabled={isLoading}
            size="dense"
            variant="light"
          />
        )}
      </Box>
    </>
  );
};
