import { Box } from '@partstech/ui';
import { FormSelectBox } from '@partstech/ui/forms';
import { uniqBy } from '@partstech/ui/utils';
import { isFulfilled } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { createStoreFromData } from 'factories';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useDynamicOptions, type DynamicOptionsFetch } from 'shared/lib/form';
import { useAppDispatch } from 'store';
import { getStoresBySupplierId } from 'store/entities/store';
import { useLazyGetPartStores } from 'store/queries/stores/partStores';
import type { Option } from '@partstech/ui';
import type { Store, Supplier } from 'models';

type SupplierStoreSelectBoxProps = {
  supplier: Supplier;
  defaultStore: Store;
};

const emptyOptions: Option[] = [];

export const LocationSelectBox = ({ supplier, defaultStore }: SupplierStoreSelectBoxProps) => {
  const {
    flags: { locationsToGraphQl },
  } = useLaunchDarkly();

  const [getPartStores] = useLazyGetPartStores();

  const dispatch = useAppDispatch();

  const fetchSupplierStore: DynamicOptionsFetch = useCallback(
    async ({ keyword, page, perPage }) => {
      if (locationsToGraphQl) {
        const { stores } = await getPartStores({
          supplierId: supplier.id,
          searchString: keyword,
          page,
          perPage,
        });

        return stores.map((store) => ({
          value: store.id,
          text: store.formattedAddress ?? '',
        }));
      }

      const action = await dispatch(
        getStoresBySupplierId({
          supplierId: supplier.id,
          keyword,
          from: (page - 1) * perPage,
          storeId: Number(defaultStore.id),
        })
      );

      if (isFulfilled(action)) {
        return action.payload.map((store) => {
          const { id, formattedAddress } = createStoreFromData(store, null);

          return { value: id, text: formattedAddress ?? '' };
        });
      }

      return emptyOptions;
    },
    [defaultStore.id, dispatch, getPartStores, locationsToGraphQl, supplier.id]
  );

  const optionPool = useMemo(
    () => [{ value: defaultStore.id, text: defaultStore.formattedAddress ?? '' }],
    [defaultStore]
  );

  const { options, onSearchInputChange, onScrollMore } = useDynamicOptions(fetchSupplierStore);

  const uniqueOptions = useMemo(() => uniqBy(options, 'value'), [options]);

  return (
    <Box mb={{ sm: 4, md: 6 }}>
      <FormSelectBox
        label="Location"
        name="storeId"
        options={uniqueOptions}
        optionPool={optionPool}
        onInputChange={onSearchInputChange}
        onScrollMore={onScrollMore}
        isSearchable
      />
    </Box>
  );
};
